import React from 'react';
import './App.css';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import {useAuthState} from 'react-firebase-hooks/auth';
import {useCollectionData} from 'react-firebase-hooks/firestore';

firebase.initializeApp({
  apiKey: "AIzaSyAY19F_Gh6may-uzDMNaUFhGBJxQfSI23k",
  authDomain: "fb-1-c06e0.firebaseapp.com",
  databaseURL: "https://fb-1-c06e0.firebaseio.com",
  projectId: "fb-1-c06e0",
  storageBucket: "fb-1-c06e0.appspot.com",
  messagingSenderId: "86604265369",
  appId: "1:86604265369:web:5c83106920043aa6560508"

})

const auth = firebase.auth();
const firestore = firebase.firestore();

function App(){


const [user] = useAuthState(auth);

  return (
    <div className="App">
      <header>

      </header>
      <section>
        {user? <ChatRoom/> : <SignIn/>}
      </section>
    </div>
  );
}

  function SignIn(){

    const signInWithGoogle = () => {
      const provider = new firebase.auth.GoogleAuthProvider();
      auth.signInWithPopup(provider);
    }

    return (
      <button onClick={signInWithGoogle}>Sign in with Google</button>
    )
  }

  function SignOut(){
    return auth.currentUser && (
      <button onClick={()=> auth.signOut()}> Sign Out</button>
    )
  }

  function ChatRoom(){
    const dummy = React.useRef()
    const messagesRef = firestore.collection('messages');
    const query = messagesRef.orderBy('createdAt').limit(25);

    const [messages] = useCollectionData(query, {idField: 'id'});
    const [formValue,setFormValue] = React.useState('');

    const sendMessage = async(e) => {
      e.preventDefault();
      const {uid, photoURL} = auth.currentUser;

      await messagesRef.add({
        text :formValue,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        uid,
        photoURL
      });
      setFormValue('');
      dummy.current.scrollIntoView({behaviour:'smooth'});
    }

    return(
      <>
      <main>
        {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}
        <div ref={dummy}></div>
      </main>

      <form onSubmit={sendMessage}>
        <input value={formValue} onChange={(e) => setFormValue(e.target.value)}/>
        <button type="submit"> submit </button>
      </form>
      </>
    )

  }



  function ChatMessage(props){
    const {text, uid, photoURL} = props.message;
    const messageClass = uid === auth.currentUser.uid ? 'sent' : 'recieved';

    return (
      <div className={'message ${messageClass}'}>
        <img src = {photoURL}/>
        <p>{text}</p>
      </div>
    )
  }
export default App;
